<template>
  <aside id="pay-statement-modal" class="modal-popup">
    <header>
      <h2>Pay Statement</h2>
      <a href="#" @click.prevent="$emit('done')" class="close"><fa-icon icon="times" /></a>
    </header>

    <footer>
      <div class="controls right">
        <el-button type="success" size="small" round @click="attemptInvoicePayment" :disabled="!canPay" :loading="loading">{{payButtonText}}</el-button>
      </div>
    </footer>

    <div class="scroll-view" v-if="statement">
      <el-alert type="error" v-if="feeStatus === 3" :closable="false" show-icon title="Your donation transaction was successful, but your processing fee transaction was declined. Please try again, or try a new card." />
      <el-alert type="info" v-if="feeStatus === 4" :closable="false" show-icon title="Your donation processing fee is still outstanding." />

      <div class="cost-breakdown">
        <div :class="[{'loading': donationStatus === 1, 'success': donationStatus === 2, 'failed': donationStatus === 3}]">
          <h4>Donations Cost</h4>
          <h5>{{$formatMoney((statement.total_amount + (statement.payment_fee/100)), '0,0.00')}}</h5>
          <h6>Paid to Universal Good, Inc.</h6>
        </div>
        <i><fa-icon icon="plus" /></i>
         <div :class="[{'loading': feeStatus === 1, 'success': feeStatus === 2, 'failed': feeStatus === 3}]">
          <h4>Platform Fee</h4>
          <h5>{{$formatMoney(statement.donation_fee/100, '0,0.00')}}</h5>
          <h6>Paid to HiThrive, Inc.</h6>
        </div>
        <i><fa-icon icon="equals" /></i>
        <div class="total">
          <h4>Total cost</h4>
          <h5>{{$formatMoney((statement.invoice_total)/100, '0,0.00')}}</h5>
        </div>
      </div>

      <p class="disclaimer">For compliance purposes, statement payments are split into two separate charges.</p>
      <p class="disclaimer">When you submit your payment, we'll authorize a charge for <strong>{{$formatMoney((statement.total_amount + (statement.payment_fee/100)), '0,0.00')}} from Universal Good, Inc.</strong> and a charge for <strong>{{$formatMoney(statement.donation_fee/100, '0,0.00')}} from HiThrive, Inc.</strong><br><br>For more information, see <a href="https://help.givecupcake.com/hc/en-us/articles/1500000099762-How-am-I-billed-for-birthdays-and-donations-" target="_blank" rel="noopener">How does Cupcake process donations?</a></p>


      <div class="add-card" v-if="addCard">
        <h4>Add new card</h4>

        <div class="field">
          <div :class="['form-control-wrap', $fieldError($v.cardDetails.name)]">
            <div>
              <input class="form-control" type="text" v-model="$v.cardDetails.name.$model" placeholder="Cardholder name..." />
            </div>
            <span class="form-control-error">{{$fieldErrorMessage($v.cardDetails.name, 'cardDetails.name', $options.validation_messages)}}</span>
          </div>
        </div>
        
        <div ref="stripeWrapper" class="stripe-wrapper faux-field"></div>

        <el-button type="primary" size="small" :loading="cardloading" round @click="saveCard">Save card</el-button>
      </div>

      <div v-else-if="!!paymentMethod">
        <div class="saved-card">
          <svg viewBox="0 0 128 82" class="card-icon"><use :xlink:href="`#${paymentMethod.paymentMethod.card.brand}-icon`"></use></svg>
          <div>
            <span>x{{paymentMethod.paymentMethod.card.last4}} <small>({{paymentMethod.paymentMethod.card.exp_month}}/{{paymentMethod.paymentMethod.card.exp_year}})</small></span>
            <a href="#" @click.prevent="addCard=true;">change card</a>
          </div>
        </div>
      </div>
    </div>

    <svg id="card-sprite">
  <defs>

    <symbol id="visa-icon">
<g>
	<path class="st0" d="M126,82H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h124c1.1,0,2,0.9,2,2v78C128,81.1,127.1,82,126,82z"/>
	<g>
		<polygon class="st1" points="47.3,56.6 52.6,25.5 61.1,25.5 55.8,56.6 		"/>
		<path class="st1" d="M86.5,26.2c-1.7-0.6-4.3-1.3-7.6-1.3c-8.4,0-14.3,4.2-14.3,10.3c0,4.5,4.2,7,7.4,8.5c3.3,1.5,4.4,2.5,4.4,3.9
			c0,2.1-2.6,3-5.1,3c-3.4,0-5.2-0.5-8-1.6l-1.1-0.5l-1.2,7c2,0.9,5.6,1.6,9.4,1.7c8.9,0,14.7-4.2,14.8-10.6c0-3.5-2.2-6.2-7.1-8.5
			c-3-1.4-4.8-2.4-4.8-3.9c0-1.3,1.5-2.7,4.9-2.7c2.8,0,4.8,0.6,6.3,1.2l0.8,0.4L86.5,26.2"/>
		<path class="st1" d="M108.3,25.5h-6.6c-2,0-3.5,0.6-4.4,2.6L84.7,56.6h8.9c0,0,1.5-3.8,1.8-4.7c1,0,9.6,0,10.9,0
			c0.3,1.1,1,4.7,1,4.7h7.9L108.3,25.5 M97.8,45.6c0.7-1.8,3.4-8.7,3.4-8.7c-0.1,0.1,0.7-1.8,1.1-3l0.6,2.7c0,0,1.6,7.4,2,9H97.8z"
			/>
		<path class="st1" d="M40.2,25.5l-8.3,21.2L31,42.4c-1.5-5-6.4-10.4-11.7-13.1l7.6,27.2l9,0l13.4-31.1H40.2"/>
		<path class="st2" d="M24.2,25.5H10.5l-0.1,0.6C21,28.7,28.1,34.9,31,42.4l-3-14.3C27.5,26.1,26,25.6,24.2,25.5"/>
	</g>
</g>
    </symbol>

    <symbol id="mastercard-icon">
<g>
	<path class="st0" d="M126,82H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h124c1.1,0,2,0.9,2,2v78C128,81.1,127.1,82,126,82z"/>
	<g>
		<path class="st1" d="M54.4,41c0-8,3.8-15.1,9.6-19.7C59.8,18,54.4,16,48.6,16c-13.8,0-25,11.2-25,25s11.2,25,25,25
			c5.8,0,11.2-2,15.4-5.3C58.2,56.1,54.4,49,54.4,41z"/>
		<path class="st2" d="M79.4,16c-5.8,0-11.2,2-15.4,5.3c5.8,4.6,9.6,11.7,9.6,19.7S69.8,56.1,64,60.7C68.2,64,73.6,66,79.4,66
			c13.8,0,25-11.2,25-25S93.2,16,79.4,16z"/>
		<path class="st3" d="M73.6,41c0-8-3.8-15.1-9.6-19.7C58.2,25.9,54.4,33,54.4,41s3.8,15.1,9.6,19.7C69.8,56.1,73.6,49,73.6,41z"/>
	</g>
</g>
    </symbol>
      
    <symbol id="amex-icon">
<g>
	<path class="st0" d="M126,82H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h124c1.1,0,2,0.9,2,2v78C128,81.1,127.1,82,126,82z"/>
	<g>
		<path class="st1" d="M21.7,27.4L9.4,54.6h14.7l1.8-4.3h4.2l1.8,4.3h16.2v-3.3l1.4,3.3h8.4l1.4-3.4v3.4h33.8l4.1-4.2l3.8,4.2
			l17.3,0l-12.4-13.6l12.4-13.7h-17.1l-4,4.2l-3.7-4.2H57.1l-3.2,7l-3.2-7H36v3.2l-1.6-3.2C34.3,27.4,21.7,27.4,21.7,27.4z
			 M24.6,31.2h7.2L40,49.7V31.2h7.9l6.3,13.3L60,31.2h7.8v19.6H63l0-15.3l-7,15.3h-4.3l-7-15.3v15.3H35l-1.9-4.4h-10l-1.9,4.4H16
			C16,50.8,24.6,31.2,24.6,31.2z M72.2,31.2h19.4l5.9,6.4l6.1-6.4h5.9l-9,9.8l9,9.7h-6.2l-5.9-6.5l-6.2,6.5H72.2L72.2,31.2
			L72.2,31.2z M28.1,34.5l-3.3,7.8h6.6L28.1,34.5z M76.9,35.3v3.6h10.6v4H76.9v3.9h11.9l5.5-5.7L89,35.3L76.9,35.3L76.9,35.3z"/>
	</g>
</g>
    </symbol>
      
    <symbol id="discover-icon">
<g>
	<path class="st0" d="M113.5,29.1h-1.1v5.6h1.1c2.3,0,3.5-1,3.5-2.9C117,30.1,115.8,29.1,113.5,29.1z"/>
	<path class="st0" d="M11.7,29.4h-1v12.2h1c2.3,0,3.8-0.4,4.9-1.5c1.2-1.1,2-2.9,2-4.7c0-1.8-0.7-3.5-2-4.6
		C15.4,29.8,14,29.4,11.7,29.4z"/>
	<path class="st0" d="M126,0H2C0.9,0,0,0.9,0,2v78c0,1.1,0.9,2,2,2h27.9C93.4,71.1,128,46.6,128,46.6V2C128,0.9,127.1,0,126,0z
		 M19,42.6c-1.8,1.5-3.8,2.1-6.6,2.1H7.1V26.2h5.3c5.8,0,9.9,3.8,9.9,9.3C22.3,38.2,21.1,40.8,19,42.6z M27.6,44.7H24V26.2h3.6V44.7
		z M35.3,45.2c-2.7,0-4.7-1.1-6.4-3.5l2.3-2.2c0.8,1.6,2.2,2.5,3.9,2.5c1.6,0,2.8-1.1,2.8-2.6c0-0.8-0.4-1.4-1.1-1.9
		c-0.4-0.2-1.1-0.6-2.5-1.1c-3.4-1.2-4.5-2.5-4.5-5.1c0-3,2.5-5.3,5.8-5.3c2,0,3.9,0.7,5.4,2l-1.9,2.5c-0.9-1.1-1.8-1.5-2.9-1.5
		c-1.6,0-2.7,0.9-2.7,2c0,1,0.6,1.5,2.8,2.3c4.1,1.5,5.3,2.8,5.3,5.8C41.7,42.7,39.1,45.2,35.3,45.2z M57.1,31.2
		c-1.5-1.5-2.8-2.1-4.5-2.1c-3.4,0-6,2.8-6,6.3c0,3.7,2.6,6.4,6.2,6.4c1.6,0,2.9-0.6,4.4-2v4.2c-1.6,0.8-3,1.1-4.5,1.1
		c-5.5,0-9.8-4.2-9.8-9.6c0-5.4,4.4-9.7,9.8-9.7c1.6,0,2.9,0.3,4.4,1.1V31.2z M68.1,45.4c-5.4,0-9.8-4.4-9.8-9.8s4.4-9.8,9.8-9.8
		c5.4,0,9.8,4.4,9.8,9.8S73.5,45.4,68.1,45.4z M86.6,45.2h-1.9l-7.8-19h3.9l4.9,12.4l5-12.4h3.9L86.6,45.2z M106.4,29.4h-6.6v4.1
		h6.4v3.1h-6.4v5h6.6v3.1H96.2V26.2h10.2V29.4z M117.9,44.7l-5-7.4h-0.5v7.4h-3.6V26.2h5.3c4.2,0,6.5,2,6.5,5.5
		c0,2.8-1.5,4.7-4.2,5.2l5.8,7.8H117.9z"/>
	<circle class="st0" cx="68.1" cy="35.5" r="9.8"/>
	<circle class="st1" cx="68.1" cy="35.5" r="9.8"/>
	<path class="st0" d="M126,82c1.1,0,2-0.9,2-2V46.6c0,0-34.6,24.4-98.1,35.4H126z"/>
	<path class="st1" d="M126,82c1.1,0,2-0.9,2-2V46.6c0,0-34.6,24.4-98.1,35.4H126z"/>
	<path class="st2" d="M12.4,26.2H7.1v18.5h5.3c2.8,0,4.8-0.7,6.6-2.1c2.1-1.7,3.4-4.4,3.4-7.1C22.3,30,18.2,26.2,12.4,26.2z
		 M16.6,40.1c-1.1,1-2.6,1.5-4.9,1.5h-1V29.4h1c2.3,0,3.7,0.4,4.9,1.5c1.2,1.1,2,2.8,2,4.6C18.6,37.2,17.8,39,16.6,40.1z"/>
	<rect x="24" y="26.2" class="st2" width="3.6" height="18.5"/>
	<path class="st2" d="M36.4,33.3c-2.2-0.8-2.8-1.3-2.8-2.3c0-1.2,1.1-2,2.7-2c1.1,0,2,0.4,2.9,1.5l1.9-2.5c-1.5-1.4-3.4-2-5.4-2
		c-3.3,0-5.8,2.3-5.8,5.3c0,2.5,1.2,3.8,4.5,5.1c1.4,0.5,2.1,0.8,2.5,1.1c0.7,0.5,1.1,1.1,1.1,1.9c0,1.5-1.2,2.6-2.8,2.6
		c-1.7,0-3.1-0.9-3.9-2.5l-2.3,2.2c1.7,2.4,3.7,3.5,6.4,3.5c3.7,0,6.4-2.5,6.4-6.1C41.7,36.2,40.5,34.8,36.4,33.3z"/>
	<path class="st2" d="M42.8,35.5c0,5.4,4.3,9.6,9.8,9.6c1.6,0,2.9-0.3,4.5-1.1v-4.2c-1.4,1.4-2.7,2-4.4,2c-3.6,0-6.2-2.6-6.2-6.4
		c0-3.5,2.7-6.3,6-6.3c1.7,0,3,0.6,4.5,2.1V27c-1.6-0.8-2.9-1.1-4.4-1.1C47.2,25.8,42.8,30.1,42.8,35.5z"/>
	<polygon class="st2" points="85.7,38.6 80.8,26.2 76.8,26.2 84.7,45.2 86.6,45.2 94.6,26.2 90.7,26.2 	"/>
	<polygon class="st2" points="96.2,44.7 106.4,44.7 106.4,41.6 99.8,41.6 99.8,36.6 106.2,36.6 106.2,33.5 99.8,33.5 99.8,29.4 
		106.4,29.4 106.4,26.2 96.2,26.2 	"/>
	<path class="st2" d="M120.7,31.7c0-3.5-2.4-5.5-6.5-5.5h-5.3v18.5h3.6v-7.4h0.5l5,7.4h4.4l-5.8-7.8
		C119.2,36.4,120.7,34.5,120.7,31.7z M113.5,34.7h-1.1v-5.6h1.1c2.2,0,3.5,0.9,3.5,2.7C117,33.7,115.8,34.7,113.5,34.7z"/>
</g>
    </symbol>

  </defs>
</svg>

  </aside>
</template>

<script>
import {required} from 'vuelidate/lib/validators'

export default {
  name: 'pay-statement-modal',
  props: ['data'],
  computed: {
    canPay() {
      return this.paymentMethod !== null && !_.isEmpty(this.paymentMethod.paymentMethod);
    },
    payButtonText() {
      if( this.feeStatus === 3 || this.feeStatus === 4 ) return `Retry fee for ${this.$formatMoney(this.statement.donation_fee/100, '0,0.00')}`;
      return `Submit Payment`;
    }
  },
  validation_messages: {
    cardDetails: {
      name: {
        required: `Cardholder name is required.`
      }
    }
  },
  data() {
    return {
      loading: false,
      cardloading: false,
      statement: null,
      stripeCard: null,
      paymentMethod: null,
      setupIntent: null,
      renderStripeElement: _.debounce( this._renderStripe, 250 ),
      paymentIntent: null,
      addCard: false,
      feeStatus: 0,
      donationStatus: 0,
      cardDetails: {
        name: ''
      }
    }
  },
  validations: {
    cardDetails: {
      name: {
        required
      }
    }
  },
  watch: {
    addCard(v) {
      if( v === true ) {
        this.renderStripeElement();
      }
    }
  },
  mounted() {
    this.statement = _.cloneDeep( this.data )

    if( this.statement.statuses[0].status == 'PAID' && this.statement.fee_invoice_id === null ) {
      this.donationStatus = 2;
      this.feeStatus = 4;
    }

    this.populatePayment()

    //this.initPayment()
  },
  methods: {
    async saveCard() {
      this.cardloading = true;

      const {setupIntent, error} = await this.$stripe.confirmCardSetup(this.setupIntent.client_secret, {
        payment_method: {
          card: this.stripeCard,
          billing_details: {
            name: this.cardDetails.name
          }
        }
      })

    
      if( error ) {
        this.cardloading = false;
        this.$message({
          type: 'error',
          message: error.message
        })
        return false;
      }

      setTimeout( () => {
        this.cardloading = false;
        this.populatePayment();
      }, 6000 );
      
      this.setupIntent = null;
    },
    _renderStripe() {
      const elements = this.$stripe.elements();

      this.stripeCard = elements.create("card")
      this.stripeCard.mount( this.$refs.stripeWrapper )

      this.setupCard()
    },
    async setupCard() {
      if( this.setupIntent === null ) {
        const resp = await this.$api.Billing.init_new_card()
        this.paymentMethod = {paymentMethod: {}};
        this.setupIntent = resp;
      }
    },
    async populatePayment() {
      this.paymentMethod = null;
      this.addCard = false;
      const resp = await this.$api.Billing.get_payment();

      this.paymentMethod = resp;
      if( _.isEmpty(resp.paymentMethod) ) {
        this.addCard = true;
      }
      else {
        this.initPayment()
      }
    },
    async initPayment() {
      this.loading = true;
      const resp = await this.$api.Statements.init_payment(this.statement.id)

      this.paymentIntent = resp;
      this.loading = false;
    },
    async attemptFeePayment() {
      this.feeStatus = 1;

      const {error, paymentIntent} = await this.$hithriveStripe.confirmCardPayment(this.paymentIntent.fee_pi_cs, {
        payment_method: this.paymentMethod.feePaymentMethod.id
      })
      this.loading = false;

      if( error ) {
        this.feeStatus = 3;
        return false;
      }

      this.feeStatus = 2;

      this.$message({
        type: 'success',
        message: `Donation statement has been successfully paid.`
      })
      this.$emit('done', 'PAID')
    },
    async attemptInvoicePayment() {
      this.loading = true;

      if( this.feeStatus === 3 || this.feeStatus === 4 ) {
        this.attemptFeePayment();
        return;
      }

      this.donationStatus = 1;

      const {error, paymentIntent} = await this.$stripe.confirmCardPayment(this.paymentIntent.invoice_pi_cs, {
        payment_method: this.paymentMethod.paymentMethod.id
      })

      if( error ) {
        this.loading = false;
        this.donationStatus = 3;
        this.$message({
          type: 'error',
          message: error.message
        })
        return false;
      }

      this.donationStatus = 2;

      this.attemptFeePayment()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-alert {
  margin: 0 0 25px;
}

.cost-breakdown {
  display: flex;
  margin: 0 -10px 25px;

  > i {
    align-self: center;
    color: #757575;
  }

  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #fff;
    box-shadow: $block-shadow;
    border-radius: 10px;
    border: 1px solid rgb(226, 226, 226);
    padding: 25px;
    text-align: center;
    margin: 0 10px;
    position: relative;
    justify-content: center;
    overflow: hidden;

    > h4 {
      font-weight: 700;
      margin: 0 0 10px;
    }
    > h5 {
      font-size: 22px;
      margin: 0 0 10px;
    }
    > h6 {
      font-size: 12px;
      color: #757575;
    }

    &:before {
      content: none;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255,255,255,0.5);
      backdrop-filter: blur(2px);
    }

    &.loading {
      &:before {
        content: '';
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        margin-top: -9px;
        margin-left: -9px;
        top: 50%;
        z-index: 9;
        left: 50%;
        background-image: url('../../../assets/sync.svg');
        background-size: 18px 18px;
        background-repeat: no-repeat;
        background-position: center center;
        animation: blockLoading 2s linear infinite;
      }
    }

    &.success {
      &:before {
        content: '';
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin-top: -12px;
        margin-left: -12px;
        top: 50%;
        z-index: 9;
        left: 50%;
        background-image: url('../../../assets/check-circle.svg');
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &.failed {
      &:before {
        content: '';
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin-top: -12px;
        margin-left: -12px;
        top: 50%;
        z-index: 9;
        left: 50%;
        background-image: url('../../../assets/times-circle.svg');
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    &.total {
      flex: 0 0 22%;
    }
  }
}

@keyframes blockLoading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

p.disclaimer {
  padding: 2px 0 2px 15px;
  line-height: 20px;
  font-size: 14px;
  position: relative;

  &+p {
    margin: 15px 0 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background: #dadada;
    border-radius: 100em;
  }
}

#card-sprite {
  display: none;
}

#visa-icon{
  .st0{fill:#194BA0;}
	.st1{fill:#FFFFFF;}
	.st2{fill:#F9A61C;}
}

#mastercard-icon{
  .st0{fill:#0E0D0E;}
	.st1{fill:#E61E25;}
	.st2{fill:#F49C1E;}
	.st3{fill:#F26122;}
}

#amex-icon{
  .st0{fill:#02AEDD;}
	.st1{fill:#FFFFFF;}
}

#discover-icon{
  .st0{fill:#FFFFFF;}
	.st1{fill:#ff5e33;}
	.st2{fill:#1F1C1C;}
}

.saved-card {
  display: flex;
  align-items: flex-start;
  max-width: 300px;
  justify-content: center;
  margin: 25px auto;
  padding: 25px;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 10px;
  background: #fff;
  box-shadow: $block-shadow;
  

  > svg {
    flex: 0 0 36px;
    margin: 0 10px 0 0;
  }

  > div {
    > span {
      display: block;
      margin: 0 0 2px;
      line-height: 16px;

      > small {
        font-size: 12px;
      }
    }

    > a {
      font-size: 12px;
      line-height: 12px;
      color: $red;
    }
  }
}

.add-card {
  max-width: 445px;
  margin: 35px auto;
  padding: 25px;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 10px;
  background: #fff;
  box-shadow: $block-shadow;

  .el-button {
    display: block;
    width: 100%;
    margin: 15px 0 0;
  }

  > h4 {
    font-weight: 700;
    margin: 0 0 15px;
  }

  .field {
    display: flex;
    margin: 0 -10px;

    &+.field,&+.faux-field {
      margin-top: 15px;
    }

    > .form-control-wrap {
      flex: 1;
      padding: 0 10px;
    }
  }

  .stripe-wrapper.StripeElement {
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    padding: 10px;

    &.StripeElement--focus {
      border: 1px solid #409EFF;
    }
  }
}
</style>